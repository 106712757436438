//general
import * as React from "react"
import { graphql } from "gatsby"
//translation
import withI18next from "../i18n/withi18n"
//components
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PrivacyPolicy from "../templates/PrivacyPolicy"
import useSplitDataByComponents from "../hooks/useSplitDataByComponents"

const PrivacyPolicyPage = ({ data }) => {
  const { footerData, headerData, privacyData, cookieAcceptData } =
    useSplitDataByComponents(data)

  return (
    <Layout
      headerData={headerData}
      footerData={footerData}
      cookieAcceptData={cookieAcceptData}
    >
      <Seo title="Cookie policy" />
      <PrivacyPolicy policyData={privacyData} />
    </Layout>
  )
}

export default withI18next()(PrivacyPolicyPage)

export const query = graphql`
  query ($lng: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          relativeDirectory: { eq: $lng }
          slug: { regex: "/privacy|header|footer|cookie-accept/" }
        }
      }
    ) {
      nodes {
        html
        frontmatter {
          links {
            path
            text
            title
          }
          header
          image
          copyrightlinks {
            name
            path
            title
          }
          contactLinks {
            name
            path
            title
            type
          }
          copyright
          titleText
          title
          text
          languageicon
          buttonText
          contacts {
            text
            title
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
